import { gql } from '@apollo/client';

export const GET_SERVICE_CATEGORIES_FOR_TENANT = gql`
query {
  tenantServiceCategories {
    id
    name
    text
    image
    colour
    sortId
    selectable
  }
}
`;
