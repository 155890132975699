import React from 'react';
import PropTypes from 'prop-types';
import { Message, Modal } from 'semantic-ui-react';

const defaultHeader = 'Ett fel uppstod';
const defaultBody = 'Försök igen eller ladda om sidan helt. Kontakta oss om problemet kvarstår: hej@propida.se eller 0707900952';

export const ERROR_MESSAGES = Object.freeze({
  NO_ORDER: {
    header: 'Inget ärende kunde hittas',
    message: 'Ärendet kan antingen ha tagits bort, avvisats eller förfallit.',
  },
  RESTRICTED_ORDER: {
    header: 'Du har inte tillgång till detta ärende',
    message: 'Din behörighet tillåter dig inte att se på detta ärende. Kontakta administratören om du anser att detta är fel.',
  },
  OFFERED_OTHER_COMPANY: {
    header: 'Inget ärende kunde hittas',
    message: 'Ärendet är inte längre aktuell eftersom ett annat företag har accepterat uppdraget.',
  },
  ORDER_CONFIRM_WRONG_STATE: {
    header: 'Ärendet kunde inte bekräftas',
    message: 'Ärendet har antingen blivit borttaget eller redan bekräftat.',
  },
  EMAIL_IN_USE: {
    header: 'E-postadressen används redan',
    message: 'Använd en annan e-postadress',
  },
  LOGIN_FAILED: {
    header: 'Inloggningen misslyckades',
    message: 'Felaktig e-postadress eller lösenord. Kontrollera dina uppgifter och försök igen.',
  },
  LOGIN_PASSWORD_NOT_SET: {
    header: 'Inloggningen misslyckades',
    message: 'Du har inte satt ett lösenord än. Följ länken i välkomstmailet du fick, eller tryck på "Glömt lösenord" och följ instruktionerna.',
  },
  RESET_PASSWORD_FAILED: {
    message: 'Det gick inte att återfå ett lösenord just nu. Var vänlig kontakta support.',
  },
  PASSWORD_RESET_MISMATCH: {
    message: 'Lösenorden stämde inte överens med varandra',
  },
  PASSWORD_RESET_INVALID: {
    message: 'Lösenordet uppfyller inte minimumkraven (minst 8 tecken varav en siffra)',
  },
  PASSWORD_RESET_NOT_FOUND: {
    message: 'Ingen lösenordsåterställning är påbörjad eller så har du redan satt ett nytt lösenord. Prova att logga in med ditt lösenord eller tryck på "Logga in" och sedan "Glömt lösenord" och följ instruktionerna.',
  },
  PASSWORD_RESET_INVALID_TOKEN: {
    message: 'Du har kommit hit med en felaktig länk. Kontrollera att du använt rätt länk för att återställa lösenordet, alternativt begär ett nytt lösenord genom att trycka på "Logga in" och sedan "Glömt lösenord" och följ instruktionerna.',
  },
  PASSWORD_RESET_EXPIRED: {
    message: 'Länken för att sätta ett lösenord har löpt ut. Tryck på "Logga in" och sedan "Glömt lösenord" och följ instruktionerna.',
  },
  PASSWORD_RESET_ERROR: {
    message: 'Något gick fel när lösenordet skulle sättas. Kontakta support.',
  },
  LOGIN_FAILED_TENANT: {
    header: 'Felaktig länk',
    message: 'Du har antagligen kommit hit genom en felaktig länk.',
  },
  LOGIN_FAILED_EXTCO: {
    header: 'Felaktig länk',
    message: 'Du har antagligen kommit hit genom en felaktig länk.',
  },
  EXTCO_ALREADY_REGISTERED: {
    header: 'Redan registrerat',
    message: 'Ert företag har redan registrerat ett konto. Logga in i systemet för att hitta ordern.',
  },
  EXTCO_ALREADY_APPLIED: {
    header: 'Redan registrerat',
    message: 'Ert företag har redan ansökt eller är redan registrerat.',
  },
  EXTCO_OTHER_CONTRACTOR: {
    header: 'Order redan tagen',
    message: 'En annan entreprenör har redan accepterat ordern.',
  },
  USER_CREATE_FAILED_EXTCO: {
    header: 'Ett fel uppstod',
    message: 'Det uppstod ett fel vid registrering. Var god kontakta info@propida.se',
  },
  EXTCO_AUTH_FAILED: {
    header: 'Ett fel uppstod',
    message: 'Det uppstod ett fel. Var god försök igen eller kontakta info@propida.se',
  },
  INTERNAL_SERVER_ERROR: {
    header: defaultHeader,
    message: defaultBody,
  },
  PROPERTY_CREATE_NO_OWNER: {
    message: 'Vald ägare kunde inte hittas. Var vänlig kontakta support.',
  },
  PROPERTY_NOT_FOUND: {
    message: 'Kunde inte hitta fastigheten.Försöker igen eller kontakta support.',
  },
  STRUCTURE_CREATE_NO_PROPERTY: {
    message: 'Vald fastighet kunde inte hittas. Var vänlig kontakta support.',
  },
  STRUCTURE_CREATE_NO_PROPERTY_TAX_TYPE: {
    message: 'Vald typ av fastighet kunde inte hittas. Var vänlig kontakta support.',
  },
  COMPANY_ALREADY_EXISTS: {
    header: 'Redan registrerat',
    message: 'Företag finns redan registrerat.',
  },
  NOAUTH_GENERIC: {
    header: 'Ett fel uppstod',
    message: 'Det uppstod ett fel. Var god försök igen eller kontakta info@propida.se',
  },
  AGREEMENT_ALREADY_EXISTS: {
    header: 'Redan registrerat',
    message: 'Detta avtalsnamn finns redan registrerat. Vänligen välj ett annat namn',
  },
  AGREEMENT_DO_NOT_EXISTS: {
    header: 'Inget avtal kunde hittas',
  },
  STATISTICS_ERROR: {
    header: 'Ett fel uppstod',
    message: 'Det uppstod ett fel vid hämtning av statistik. Var god försök igen eller kontakta support',
  },
  INTEGRATION_NO_STATE: {
    header: 'Ett fel uppstod med att skapa integrationen',
    message: 'Du kan ha kommit hit med en felaktig länk eller så har du använt samma länk två gånger. Prova att gå till integrationer för att se om den skapats korrekt redan.',
  },
  INTEGRATION_REFRESH_TOKEN_EXPIRED: {
    header: 'Ett fel uppstod vid anslutning mot integration',
    message: 'Gå till inställningar / integrationer och säkerställ att kopplingen till integrationen fungerar.',
  },
});

const ErrorMessage = ({
  header = defaultHeader,
  body = defaultBody,
  errorCode = null,
  graphqlError = null,
  modal = false,
  onModalClose = null,
}) => {
  // const [visible, setVisible] = useState(true);
  const hasGraphqlErrors = graphqlError && graphqlError.graphQLErrors && graphqlError.graphQLErrors.length > 0;
  if (hasGraphqlErrors) errorCode = graphqlError.graphQLErrors[0].extensions.code;

  if (errorCode && ERROR_MESSAGES[errorCode]) {
    header = ERROR_MESSAGES[errorCode].header;
    body = ERROR_MESSAGES[errorCode].message;
  } else if (hasGraphqlErrors) {
    body = graphqlError.graphQLErrors[0].message;
  }

  // if (!visible) return null;

  const content = (
    <Message
      header={header}
      negative
      content={body}
    />
  );

  console.log({ header, body, errorCode, graphqlError, modal, onModalClose });

  if (!modal) return content;

  return (
    <Modal defaultOpen closeIcon onClose={() => onModalClose && onModalClose()}>
      <Modal.Content>
        {content}
      </Modal.Content>
    </Modal>
  );
};

ErrorMessage.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  errorCode: PropTypes.string,
  modal: PropTypes.bool,
  graphqlError: PropTypes.shape({}),
  onModalClose: PropTypes.func,
};

export default ErrorMessage;
