export const GRADE_CATEGORIES = {
  quality: {
    text: 'Kvalitét',
    translationId: 'enums.grade-categories.quality',
  },
  price: {
    text: 'Pris',
    translationId: 'enums.grade-categories.price',
  },
  speed: {
    text: 'Snabbhet',
    translationId: 'enums.grade-categories.speed',
  },
};

export const AGREEMENT_TYPES = {
  file: {
    text: 'Fil',
    translationId: 'enums.agreement-type.file',
  },
  url: {
    text: 'Länk',
    translationId: 'enums.agreement-type.url',
  },
};

export const ACCESS_TYPES = {
  door: {
    text: 'Någon öppnar',
    translationId: 'enums.access-type.door',
  },
  tube: {
    text: 'Nyckel i tub',
    translationId: 'enums.access-type.tube',
  },
  office: {
    text: 'Nyckel hämtas på kontor',
    translationId: 'enums.access-type.office',
  },
  main_key: {
    text: 'Använd huvudnyckel',
    translationId: 'enums.access-type.main_key',
  },
  service_key: {
    text: 'Servicenyckel',
    translationId: 'enums.access-type.service_key',
  },
  irrelevant: {
    text: 'Inte relevant',
    translationId: 'enums.access-type.irrelevant',
  },
};

export const ROOM_TYPES = {
  hallway: {
    text: 'Hall',
    translationId: 'enums.room-types.hallway',
  },
  bath1: {
    text: 'Badrum 1',
    translationId: 'enums.room-types.bath1',
  },
  bath2: {
    text: 'Badrum 2',
    translationId: 'enums.room-types.bath2',
  },
  living: {
    text: 'Vardagsrum',
    translationId: 'enums.room-types.living',
  },
  kitchen: {
    text: 'Kök',
    translationId: 'enums.room-types.kitchen',
  },
  bedroom1: {
    text: 'Sovrum 1',
    translationId: 'enums.room-types.bedroom1',
  },
  bedroom2: {
    text: 'Sovrum 2',
    translationId: 'enums.room-types.bedroom2',
  },
  bedroom3: {
    text: 'Sovrum 3',
    translationId: 'enums.room-types.bedroom3',
  },
  balcony: {
    text: 'Balkong',
    translationId: 'enums.room-types.balcony',
  },
  full: {
    text: 'Hela lägenheten',
    translationId: 'enums.room-types.full',
  },
  other: {
    text: 'Annat',
    translationId: 'enums.room-types.other',
  },
};

export const COMMON_SPACE_TYPES = {
  pram_room: {
    text: 'Barnvagnsrum',
    translationId: 'enums.common-space-type.pram_room',
  },
  bike_room: {
    text: 'Cykelrum',
    translationId: 'enums.common-space-type.bike_room',
  },
  entrance: {
    text: 'Entré',
    translationId: 'enums.common-space-type.entrance',
  },
  association_room: {
    text: 'Föreningslokal',
    translationId: 'enums.common-space-type.association_room',
  },
  storage: {
    text: 'Förråd',
    translationId: 'enums.common-space-type.storage',
  },
  garage: {
    text: 'Garage',
    translationId: 'enums.common-space-type.garage',
  },
  guest_apartment: {
    text: 'Gästlägenhet',
    translationId: 'enums.common-space-type.guest_apartment',
  },
  cellar: {
    text: 'Källare',
    translationId: 'enums.common-space-type.cellar',
  },
  locality: {
    text: 'Lokal',
    translationId: 'enums.common-space-type.locality',
  },
  parking_spot: {
    text: 'P-plats',
    translationId: 'enums.common-space-type.parking_spot',
  },
  garbage_room: {
    text: 'Soprum',
    translationId: 'enums.common-space-type.garbage_room',
  },
  roof: {
    text: 'Tak',
    translationId: 'enums.common-space-type.roof',
  },
  staircase: {
    text: 'Trapphus',
    translationId: 'enums.common-space-type.staircase',
  },
  laundry_room: {
    text: 'Tvättstuga',
    translationId: 'enums.common-space-type.laundry_room',
  },
  outside: {
    text: 'Utvändigt',
    translationId: 'enums.common-space-type.outside',
  },
  attic: {
    text: 'Vind',
    translationId: 'enums.common-space-type.attic',
  },
  other_common_space: {
    text: 'Övrigt',
    translationId: 'enums.common-space-type.other_common_space',
  },
};

export const TYPE_OF_SPACES = {
  room_types: {
    text: 'Rum',
    translationId: 'enums.type-of-space.room_types',
  },
  common_space_types: {
    text: 'Utrymmen',
    translationId: 'enums.type-of-space.common_space_types',
  },
};

export const USER_TYPES = {
  propcomp: {
    text: 'förvaltare',
    translationId: 'enums.user-type.propcomp',
    bgClass: 'bg-propcomp',
    colorClass: 'color-white',
  },
  contractor: {
    text: 'entreprenör',
    translationId: 'enums.user-type.contractor',
    bgClass: 'bg-contractor',
    colorClass: 'color-white',
  },
  tenant: {
    text: 'slutkund',
    translationId: 'enums.user-type.tenant',
    bgClass: 'bg-sandsten',
    colorClass: 'color-white',
  },
};

export const USER_TYPES_FOR_TENANT = {
  propcomp: {
    text: 'förvaltare',
    translationId: 'enums.user-type-for-tenant.propcomp',
  },
  contractor: {
    text: 'utförare',
    translationId: 'enums.user-type-for-tenant.contractor',
  },
  tenant: {
    text: 'slutkund',
    translationId: 'enums.user-type-for-tenant.tenant',
  },
};

export const USER_ROLES = {
  admin: {
    text: 'Admin',
    translationId: 'enums.user-role.admin',
    descriptionTranslation: {
      propcomp: 'enums.user-role.admin-description',
      contractor: 'enums.user-role.admin-contractor-description',
    },
  },
  standard: {
    text: 'Standard',
    translationId: 'enums.user-role.standard',
    descriptionTranslation: {
      propcomp: 'enums.user-role.standard-description',
      contractor: 'enums.user-role.standard-description',
    },
  },
  restricted: {
    text: 'Begränsad',
    translationId: 'enums.user-role.restricted',
    descriptionTranslation: {
      propcomp: 'enums.user-role.restricted-description',
      contractor: 'enums.user-role.restricted-description',
    },
  },
};

export const PRICE_INDICATORS = {
  low: {
    color: 'green',
    text: 'Låg',
    translationId: 'enums.price-indicator.low',
  },
  medium: {
    color: 'yellow',
    text: 'Medel',
    translationId: 'enums.price-indicator.medium',
  },
  high: {
    color: 'red',
    text: 'Hög',
    translationId: 'enums.price-indicator.high',
  },
};

export const DISTANCES = {
  km5: {
    text: 'Inom 5 km',
    translationId: 'enums.distance.km5',
  },
  km10: {
    text: 'Inom 10 km',
    translationId: 'enums.distance.km10',
  },
  km20: {
    text: 'Inom 20 km',
    translationId: 'enums.distance.km20',
  },
  km30above: {
    text: 'Utanför 20 km',
    translationId: 'enums.distance.km30above',
  },
};

export const COMPANY_SORTING = {
  smart: {
    text: 'Smart (Kommer senare)',
    translationId: 'enums.company-sorting.smart',
  },
  environment: {
    text: 'Miljöpoäng (Kommer senare)',
    translationId: 'enums.company-sorting.environment',
  },
  price: {
    text: 'Prisnivå (Kommer senare)',
    translationId: 'enums.company-sorting.price',
  },
  distance: {
    text: 'Avstånd (Kommer senare)',
    translationId: 'enums.company-sorting.distance',
  },
  name: {
    text: 'Namn',
    translationId: 'enums.company-sorting.name',
  },
  rating: {
    text: 'Betyg',
    translationId: 'enums.company-sorting.rating',
  },
};

export const PROPERTY_SORTING = {
  name: {
    text: 'Namn',
    translationId: 'enums.property-sorting.name',
  },
};

export const OWNER_SORTING = {
  organization_name: {
    text: 'Namn',
    translationId: 'enums.owner-sorting.organization_name',
  },
  identity_number: {
    text: 'Org/Pnr',
    translationId: 'enums.owner-sorting.identity_number',
  },
  owner_type: {
    text: 'Typ av ägare',
    translationId: 'enums.owner-sorting.owner_type',
  },
};

export const ORDER_STATUS_SETTING_GROUPS = Object.freeze({
  unhandled: {
    text: 'Ej hanterade',
    translationId: 'enums.order-status-setting-groups.not_started',
    bgClass: 'bg-sandsten',
    bgHexClass: '#efb492',
    colorClass: 'color-fogmassa',
  },
  ongoing: {
    text: 'Pågående',
    translationId: 'enums.order-status-setting-groups.ongoing',
    bgClass: 'bg-safir-light',
    bgHexClass: '#AAB6C1',
    colorClass: 'color-ametist',
  },
  finished: {
    text: 'Avslutade',
    translationId: 'enums.order-status-setting-groups.finished',
    bgClass: 'bg-skog-light',
    bgHexClass: '#C1D5CC',
    colorClass: 'color-skog',
  },
  recurring: {
    text: 'Återkommande',
    translationId: 'enums.order-status-setting-groups.recurring',
    bgClass: 'bg-safir-light',
    bgHexClass: '#AAB6C1',
    colorClass: 'color-safir',
  },
});

export const ORDER_STATUS_GROUP_MAP = {
  contractor: 'contractorGroup',
  propcomp: 'propcompGroup',
};

export const ORDER_STATUS_SETTINGS = Object.freeze({
  pending_sending: {
    percent: 0,
    text: 'Mottaget',
    translationId: 'enums.order-status-settings.pending_sending',
    color: '#4D594F',
    bgClass: 'bg-sandsten',
    bgHexClass: '#efb492',
    colorClass: 'color-fogmassa',
    hideForUserTypes: ['contractor'],
    propcompGroup: 'unhandled',
  },
  pending_confirmation: {
    percent: 25,
    text: 'Avvaktar bekräftelse',
    translationId: 'enums.order-status-settings.pending_confirmation',
    color: '#7B91A3',
    bgClass: 'bg-blekros',
    bgHexClass: '#cbbda8',
    colorClass: 'color-fogmassa',
    hideForUserTypes: [],
    propcompGroup: 'ongoing',
    contractorGroup: 'unhandled',
  },
  rejected: {
    percent: 0,
    text: 'Avvisad',
    translationId: 'enums.order-status-settings.rejected',
    color: '#BF6155',
    bgClass: 'bg-rubin-light',
    bgHexClass: '#C29FAC',
    colorClass: 'color-tegel',
    hideForUserTypes: ['contractor'],
    propcompGroup: 'ongoing',
  },
  pending_work: {
    percent: 50,
    text: 'Hanteras',
    translationId: 'enums.order-status-settings.pending_work',
    color: '#D88A55',
    bgClass: 'bg-safir-light',
    bgHexClass: '#AAB6C1',
    colorClass: 'color-ametist',
    hideForUserTypes: [],
    propcompGroup: 'ongoing',
    contractorGroup: 'ongoing',
  },
  pending_rating: {
    percent: 75,
    text: 'Väntar på klarmarkering',
    translationId: 'enums.order-status-settings.pending_rating',
    color: '#639780',
    bgClass: 'bg-mossa-light',
    bgHexClass: '#C1BD99',
    colorClass: 'color-mossa',
    hideForUserTypes: [],
    propcompGroup: 'ongoing',
    contractorGroup: 'ongoing',
  },
  completed: {
    percent: 100,
    text: 'Klar',
    translationId: 'enums.order-status-settings.completed',
    color: '#386357',
    bgClass: 'bg-skog-light',
    bgHexClass: '#C1D5CC',
    colorClass: 'color-skog',
    hideForUserTypes: [],
    propcompGroup: 'finished',
    contractorGroup: 'finished',
  },
  deleted: {
    percent: 0,
    text: 'Borttagen',
    translationId: 'enums.order-status-settings.deleted',
    color: '#845236',
    bgClass: 'bg-shadow-light',
    bgHexClass: '#555555',
    colorClass: 'color-shadow',
    hideForUserTypes: ['contractor'],
    propcompGroup: 'finished',
  },
  recurring: {
    percent: 0,
    text: 'Återkommande',
    translationId: 'enums.order-status-settings.recurring',
    color: 'pink',
    bgClass: 'bg-safir-light',
    bgHexClass: '#555555',
    colorClass: 'color-safir',
    hideForUserTypes: ['contractor'],
    propcompGroup: 'recurring',
  },
});

export const ORDER_FILTERS = Object.freeze({
  street: {
    text: 'Adress',
    translationId: 'enums.order-filters.street',
  },
  zip: {
    text: 'Postnummer',
    translationId: 'enums.order-filters.zip',
  },
  city: {
    text: 'Ort',
    translationId: 'enums.order-filters.city',
  },
  apartmentId: {
    text: 'Objektsnummer',
    translationId: 'enums.order-filters.apartmentId',
  },
  orderNumber: {
    text: 'Arbetsordernummer',
    translationId: 'enums.order-filters.orderNumber',
  },
  description: {
    text: 'Beskrivning',
    translationId: 'enums.order-filters.description',
  },
  company: {
    text: 'Företag',
    translationId: 'enums.order-filters.company',
  },
  statuses: {
    text: 'Status',
    translationId: 'enums.order-filters.statuses',
  },
  userIds: {
    text: 'Resurser',
    translationId: 'enums.order-filters.userIds',
  },
});

export const COMMUNICATION_TRANSPORT_TYPES = Object.freeze({
  sms: {
    icon: 'sms',
    text: 'SMS',
    translationId: 'enums.communication-transport-types.sms',
    color: 'bg-safir-light',
  },
  email: {
    icon: 'email',
    text: 'E-post',
    translationId: 'enums.communication-transport-types.email',
    color: 'bg-skog-light',
  },
});

export const PERSON_TYPES = Object.freeze({
  natural: {
    text: 'Fysisk person',
    translationId: 'enums.person-type.natural',
  },
  legal: {
    text: 'Juridisk person',
    translationId: 'enums.person-type.legal',
  },
});

export const COMPANY_OFFER_STATUS = Object.freeze({
  init: {
    text: 'Ej skickat',
    translationId: 'enums.company-offer-status.init',
    class: 'bg-grafit-light',
  },
  sent: {
    text: 'Skickat',
    translationId: 'enums.company-offer-status.sent',
    class: 'bg-ockra-light',
  },
  accepted: {
    text: 'Accepterat',
    translationId: 'enums.company-offer-status.accepted',
    class: 'bg-skog-light',
  },
  rejected: {
    text: 'Tackat nej',
    translationId: 'enums.company-offer-status.rejected',
    class: 'bg-tegel-light',
  },
});

export const ADDRESS_TYPES = Object.freeze({
  location: {
    text: 'Belägenhetsadress',
    translationId: 'enums.address-type.location',
  },
  delivery: {
    text: 'Leveransadress',
    translationId: 'enums.address-type.delivery',
  },
  main: {
    text: 'Huvudadress',
    translationId: 'enums.address-type.main',
  },
  visitor: {
    text: 'Besöksadress',
    translationId: 'enums.address-type.visitor',
  },
  postal: {
    text: 'Postadress',
    translationId: 'enums.address-type.postal',
  },
  invoice: {
    text: 'Faktureringsadress',
    translationId: 'enums.address-type.invoice',
  },
});

export const LOCALES_SUPPORTED = Object.freeze({
  sv: {
    text: 'Svenska',
    translationId: 'enums.locales.sv',
    flag: 'se',
  },
  en: {
    text: 'English',
    translationId: 'enums.locales.en',
    flag: 'gb',
  },
});

export const ADDRESS_COUNTRIES = Object.freeze({
  sweden: {
    text: 'Sverige',
    translationId: 'enums.countries.sweden',
    dropdownIcon: 'se',
  },
});

export const ROOMS_KITCHEN = Object.freeze({
  one_rok: {
    text: '1 rok',
    translationId: 'enums.rooms-kitchen.one_rok',
  },
  two_rok: {
    text: '2 rok',
    translationId: 'enums.rooms-kitchen.two_rok',
  },
  three_rok: {
    text: '3 rok',
    translationId: 'enums.rooms-kitchen.three_rok',
  },
  four_rok: {
    text: '4 rok',
    translationId: 'enums.rooms-kitchen.four_rok',
  },
  five_rok: {
    text: '5 rok',
    translationId: 'enums.rooms-kitchen.five_rok',
  },
  more_rok: {
    text: '6+ rok',
    translationId: 'enums.rooms-kitchen.more_rok',
  },
  partly: {
    text: 'Del av lägenhet',
    translationId: 'enums.rooms-kitchen.partly',
  },
});

export const STOVE_VOLTAGE = Object.freeze({
  230: {
    text: '230v',
    translationId: 'enums.stove-voltage.230',
  },
  400: {
    text: '400v',
    translationId: 'enums.stove-voltage.400',
  },
});

export const STOVE_WIDTH = Object.freeze({
  55: {
    text: '55cm',
    translationId: 'enums.stove-width.55',
  },
  60: {
    text: '60cm',
    translationId: 'enums.stove-width.60',
  },
  70: {
    text: '70cm',
    translationId: 'enums.stove-width.70',
  },
});

export const HOB_TYPE = Object.freeze({
  induction: {
    text: 'Induktion',
    translationId: 'enums.hob-type.induction',
  },
  ceramic: {
    text: 'Glaskeramik',
    translationId: 'enums.hob-type.ceramic',
  },
  gas: {
    text: 'Gas',
    translationId: 'enums.hob-type.gas',
  },
});

export const DISHWASHWER_WIDTH = Object.freeze({
  45: {
    text: '45cm',
    translationId: 'enums.dishwasher-width.45',
  },
  55: {
    text: '55cm',
    translationId: 'enums.dishwasher-width.55',
  },
  60: {
    text: '60cm',
    translationId: 'enums.dishwasher-width.60',
  },
});

export const FRIDGE_FREEER_HEIGHT = Object.freeze({
  825: {
    text: '825mm',
    translationId: 'enums.fridge-freezer-height.825',
  },
  1550: {
    text: '1550mm',
    translationId: 'enums.fridge-freezer-height.1550',
  },
  1750: {
    text: '1750mm',
    translationId: 'enums.fridge-freezer-height.1750',
  },
  1850: {
    text: '1850mm',
    translationId: 'enums.fridge-freezer-height.1850',
  },
  1860: {
    text: '1860mm',
    translationId: 'enums.fridge-freezer-height.1860',
  },
  1875: {
    text: '1875mm',
    translationId: 'enums.fridge-freezer-height.1875',
  },
  1890: {
    text: '1890mm',
    translationId: 'enums.fridge-freezer-height.1890',
  },
  2011: {
    text: '2011mm',
    translationId: 'enums.fridge-freezer-height.2011',
  },
  2030: {
    text: '2030mm',
    translationId: 'enums.fridge-freezer-height.2030',
  },
});

export const DOOR_SWING = Object.freeze({
  left: {
    text: 'Vänsterhängd',
    translationId: 'enums.door-swing.left',
  },
  right: {
    text: 'Högerhängd',
    translationId: 'enums.door-swing.right',
  },
});

export const LAUNDRY_MACHINE_TYPE = Object.freeze({
  front: {
    text: 'Frontmatad',
    translationId: 'enums.laundry-machine-type.front',
  },
  top: {
    text: 'Toppmatad',
    translationId: 'enums.laundry-machine-type.top',
  },
});

export const INTEGRATION_TYPES = Object.freeze({
  economy_system: {
    text: 'Ekonomisystem',
  },
});

// export const userTypes = () => Object.keys(USER_TYPES).map((key) => key);

export const getTextValue = (input, formatMessage) => {
  if (formatMessage && input?.translationId) return formatMessage({ id: input.translationId });
  return input?.text || input;
};

export const getTextValues = (input, formatMessage) => {
  const obj = {};
  Object.entries(input).forEach(([key, value]) => {
    if (formatMessage && value.translationId) {
      obj[key] = formatMessage({ id: value.translationId });
    } else {
      obj[key] = value.text || value;
    }
  });
  return obj;
};

export const getTextValueWithFallback = (input, value, formatMessage, fallback = '-') => {
  if (input[value]) {
    if (formatMessage && input[value].translationId) return formatMessage({ id: input[value].translationId });
    return input[value].text || input[value];
  }
  return fallback;
};

// In cases where additional values can be added to dropdowns, the value itself must be used as fallback as it does not exist in the enum/translation
export const getTextValueWithValueFallback = (input, value, formatMessage) => {
  if (input[value]) {
    if (formatMessage && input[value].translationId) return formatMessage({ id: input[value].translationId });
    return input[value].text;
  }
  return value;
};

export const rruleTranslations = {
  sv: {
    locale: 'sv-se',
    invalid_rrule: "You provided an invalid RRule value to component. '%{value}' is not a correct RRule string.",
    months: {
      jan: 'januari',
      feb: 'februari',
      mar: 'mars',
      apr: 'april',
      may: 'maj',
      jun: 'juni',
      jul: 'juli',
      aug: 'augusti',
      sep: 'september',
      oct: 'oktober',
      nov: 'november',
      dec: 'december',
    },
    days_short: {
      mon: 'mån',
      tue: 'tis',
      wed: 'ons',
      thu: 'tor',
      fri: 'fre',
      sat: 'lör',
      sun: 'sön',
    },
    days: {
      monday: 'måndagen',
      tuesday: 'tisdagen',
      wednesday: 'onsdagen',
      thursday: 'torsdagen',
      friday: 'fredagen',
      saturday: 'lördagen',
      sunday: 'söndagen',
      day: 'dag',
      weekday: 'veckodag',
      'weekend day': 'helgdag',
    },
    numerals: {
      first: 'första',
      second: 'andra',
      third: 'tredje',
      fourth: 'fjärde',
      last: 'sista',
    },
    start: {
      label: 'Startdatum',
      tooltip: 'Datumväljare för startdatum',
    },
    repeat: {
      label: 'Repetera',
      yearly: {
        label: 'Årligen',
        every: 'var',
        years: 'år',
        on: 'på',
        on_the: 'på den',
        of: 'i',
      },
      monthly: {
        label: 'Månadsvis',
        every: 'var',
        months: 'månad',
        on_day: 'på dag',
        on_the: 'på den',
      },
      weekly: {
        label: 'Veckovis',
        every: 'var',
        weeks: 'vecka',
      },
      daily: {
        label: 'Dagligen',
        every: 'var',
        days: 'dag',
      },
      hourly: {
        label: 'Varje timme',
        every: 'var',
        hours: 'timme',
      },
    },
    end: {
      label: 'Slutdatum',
      tooltip: 'Datumväljare för slutdatum',
      never: 'Aldrig',
      after: 'Efter',
      on_date: 'På datum',
      executions: 'gånger',
    },
  },
};

const rruleTranslation = {
  every: 'varje',
  until: 'till',
  day: 'dag',
  days: 'dagar',
  week: 'vecka',
  weeks: 'veckor',
  on: 'på',
  in: 'i',
  'on the': 'på den',
  for: 'för',
  and: 'och',
  or: 'eller',
  at: 'på',
  last: 'sista',
  st: '.,',
  nd: '.,',
  rd: '.,',
  th: '.,',
  '(~ approximate)': '(~ ungefärligt)',
  times: 'tider',
  time: 'tid',
  minutes: 'minuter',
  hours: 'timmar',
  weekdays: 'veckodagar',
  weekday: 'veckodag',
  months: 'månader',
  month: 'månad',
  years: 'år',
  year: 'år',
};

export const rruleConfig = {
  getText: (id) => rruleTranslation[id] || id,
  language: {
    dayNames: [
      'söndag',
      'måndag',
      'tisdag',
      'onsdag',
      'torsdag',
      'fredag',
      'lördag',
    ],
    monthNames: [
      'januari',
      'februari',
      'mars',
      'april',
      'maj',
      'juni',
      'juli',
      'augusti',
      'september',
      'oktober',
      'november',
      'december',
    ],
  },
  dateFormat: (year, month, day) => `${day}. ${month} ${year}`,
};

export const invoiceExternalDetailsLabels = {
  invoiceNumber: 'Fakturanummer',
  customerName: 'Kundnamn',
  customerNumber: 'Kundnummer',
  invoiceDate: 'Fakturadatum',
  dueDate: 'Förfallodatum',
  finalPayDate: 'Betaldatum',
  total: 'Totalbelopp',
  totalToPay: 'Att betala',
  totalVAT: 'Moms',
};
