export const addScopeToSentry = (sentry, userInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const clientVersion = process.env.REACT_APP_VERSION;
  const websiteType = process.env.REACT_APP_WEBSITE_TYPE;

  let environment = 'development';
  if (apiUrl.includes('staging')) environment = 'staging';
  else environment = 'production';

  const clientInfo = {
    clientVersion,
    websiteType,
    environment,
  };

  sentry.setUser(userInfo);
  sentry.setExtra('clientInfo', clientInfo);
};
