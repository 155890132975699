import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { UserContext } from './UserContext';
import { GET_WORK_CATEGORIES_FOR_PROPCOMP } from '../../graphql_queries/work_categories';
import { GET_WORK_CATEGORIES_FOR_CONTRACTOR } from '../../graphql_queries/work_categories.contractor';
import { GET_WORK_CATEGORIES_FOR_TENANT } from '../../graphql_queries/work_categories.tenant';

const GET_QUERY = {
  propcomp: GET_WORK_CATEGORIES_FOR_PROPCOMP,
  contractor: GET_WORK_CATEGORIES_FOR_CONTRACTOR,
  tenant: GET_WORK_CATEGORIES_FOR_TENANT,
};

const DATA_PROPERTY = {
  propcomp: 'workCategories',
  contractor: 'contractorWorkCategories',
  tenant: 'tenantWorkCategories',
};

function ConditionalProviderWithHook({ userType, children }) {
  const { loading, error, data } = useQuery(GET_QUERY[userType]);

  const value = useMemo(
    () => ({
      loading,
      error,
      data: data ? data[DATA_PROPERTY[userType]] : null,
    }),
    [loading, error, data],
  );

  return <WorkCategoriesContext.Provider value={value}>{children}</WorkCategoriesContext.Provider>;
}

const WorkCategoriesContext = React.createContext([{}, () => {}]);
function WorkCategoriesProvider({ children }) {
  const { user } = useContext(UserContext);

  if (!user || user.userType === 'tenant' || user.userType === 'extco') {
    return children;
  }

  return <ConditionalProviderWithHook userType={user.userType}>{children}</ConditionalProviderWithHook>;
}

ConditionalProviderWithHook.propTypes = {
  children: PropTypes.node.isRequired,
  userType: PropTypes.string.isRequired,
};

WorkCategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WorkCategoriesContext;

export { WorkCategoriesContext, WorkCategoriesProvider };
