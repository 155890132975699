import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { CREATE_ERROR } from '../../graphql_queries/errors';
import { getUser } from '../../utils/auth';

// TODO: Must clean this component
const Loader = ({ showSlowLoading = false, style, debugData }) => {
  const [slowLoading, setSlowLoading] = useState(false);
  const [createError, { data: dataCreate, error: errorCreate }] = useMutation(CREATE_ERROR, { onError: () => {} });

  if (showSlowLoading) {
    setTimeout(() => setSlowLoading(true), 3000);
  }

  useEffect(() => {
    if (slowLoading) {
      console.log('Loading is slow');
      // const user = getUser();
      // createError({ variables: {
      //   errorMessage: 'Loading is slow',
      //   errorStack: null,
      //   errorInfo: JSON.stringify({ debugData, location: window.location.href }),
      //   userInfo: user && JSON.stringify(user),
      // } });
    }
  }, [slowLoading]);

  return (
    <div className="centered" style={{ flexDirection: 'column', ...style }}>
      <div className="ui active inline loader" />
      {slowLoading && showSlowLoading && (
        <>
          <br />
          <p>
            Laddar inte sidan ordentligt?
            <br />
            <button
              type="button"
              className="textLink"
              onClick={() => window.location.reload(true)}
            >
              Prova att ladda om
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default Loader;
