import React from 'react';
import { useLocation } from 'react-router-dom';

import { init, sendPageview } from '../apis/ga';

export default function useGoogleAnalytics(gaId) {
  const location = useLocation();

  React.useEffect(() => {
    init(gaId);
  }, []);

  React.useEffect(() => {
    // TODO: Since the search can contain sensitive information, we don't want to send that to Google.
    // Sometimes that info can be good though. Is there a way to achieve this?
    // const currentPath = location.pathname + location.search;
    const currentPath = location.pathname;
    sendPageview(currentPath);
  }, [location]);
}
