import { React } from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function ErrorFallback({ modal = false, onModalClose }) {
  const content = (
    <>
      <h1 className="ui header">Oj! Något gick fel och vi har blivit underrättade om detta.</h1>
      <p>Vi beklagar besväret. Du kan prova att ladda om sidan med knappen nedan eller trycka på tillbaka-knappen i webbläsaren</p>
      <div className="sixteen wide column">
        <button type="button" className="ui button primary" onClick={() => window.location.reload()}>
          Ladda om sidan
        </button>
        {/* <button type="button" className="ui button secondary" onClick={goBack}>
          Gå tillbaka
        </button> */}
      </div>
      <br />
      <div className="sixteen wide column">
        <p>
          Eller kontakta vår support för vidare hjälp:
          {' '}
          <a href="mailto:hej@propida.se">hej@propida.se</a>
        </p>
      </div>
    </>
  );

  if (!modal) {
    return (
      <div className="ui container" style={{ marginTop: 50 }}>
        <div className="ui very padded segment">
          {content}
        </div>
      </div>
    );
  }

  return (
    <Modal defaultOpen closeIcon onClose={() => onModalClose && onModalClose()}>
      <Modal.Content>
        {content}
      </Modal.Content>
    </Modal>
  );
}

ErrorFallback.propTypes = {
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
};

export default ErrorFallback;
