import React, { createContext, useContext, useEffect, useState } from 'react';

// Create a context for the settings
const SettingsContext = createContext();

// Provide the settings to your application
export const UserSettingsContext = ({ children }) => {
  const [settings, setSettings] = useState(() => {
    // Try to load the settings from localStorage when initializing state
    const savedSettings = localStorage.getItem('userSettings');
    return savedSettings ? JSON.parse(savedSettings) : {};
  });

  // Save the settings to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('userSettings', JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      {children}
    </SettingsContext.Provider>
  );
};

// Create a custom hook to use the settings
export const useSettings = () => {
  const [settings, setSettings] = useContext(SettingsContext);

  if (!settings) {
    throw new Error('useSettings must be used within a UserSettingsContext');
  }

  const getSetting = (key) => settings[key];

  const setSetting = (key, value) => setSettings((prevSettings) => ({
    ...prevSettings,
    [key]: value,
  }));

  return {
    settings,
    getSetting,
    setSetting,
  };
};
