/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_ERROR = gql`
  mutation createError($errorMessage: String!, $errorStack: String, $errorInfo: String, $userInfo: String, $url: String, $clientInfo: String) {
    createError(errorMessage: $errorMessage, errorStack: $errorStack, errorInfo: $errorInfo, userInfo: $userInfo, url: $url, clientInfo: $clientInfo) {
      success
    }
  }
`;
