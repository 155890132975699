import React, { useEffect } from 'react';

import { getUserFromToken } from '../../utils/auth';
import history from '../../history';
import { USER_TYPES } from '../../constants/enums';
import { updateRules } from '../../config/ability';
import { useLocalStorage } from '../../hooks/localstorage';
import { sendEvent } from '../../apis/ga';

const UserContext = React.createContext([{}, () => {}]);

// Vi har ett state som är token

const TOKEN_KEY = process.env.REACT_APP_TOKEN_NAME;

function UserProvider({ children }) {
  const [tokenData, setTokenData] = useLocalStorage(TOKEN_KEY, null);

  const logout = () => {
    sendEvent({ category: 'account', action: 'logout' });
    setTokenData(null);
  };

  const login = (token) => {
    sendEvent({ category: 'account', action: 'login' });
    setTokenData(token);
    const tempUser = getUserFromToken(token);
    updateRules(tempUser.userType, tempUser.userRole, tempUser.client?.type);
  };

  const user = getUserFromToken(tokenData);

  const redirectToStart = (url = null) => {
    const { userType } = user;
    if (url) history.push(url);
    else {
      if (userType === USER_TYPES.PROPCOMP) history.push('/overview');
      if (userType === USER_TYPES.CONTRACTOR) history.push('/contractor/overview');
    }
  };

  useEffect(() => {
    if (user) {
      updateRules(user.userType, user.userRole, user.client?.type);
    }
  }, [tokenData]);

  return (
    <UserContext.Provider value={{ user, logout, login, redirectToStart }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;

export { UserContext, UserProvider };
