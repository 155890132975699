import ReactGA from 'react-ga4';

export const init = (gaId) => {
  ReactGA.initialize(gaId);
};

export const sendEvent = (payload) => {
  if (ReactGA.isInitialized) {
    ReactGA.event(payload);
  }
};

export const sendPageview = (path) => {
  if (ReactGA.isInitialized) {
    ReactGA.set({ page: path });
    ReactGA.send('pageview');
  }
};
