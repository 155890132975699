/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, Observable, HttpLink } from '@apollo/client';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import moment from 'moment';
import 'moment/locale/sv';
import 'moment/locale/es';
import * as Sentry from '@sentry/react';

import App from './App';
import ErrorMessage from './components/common/ErrorMessage';
import { getTokens, saveTokens } from './utils/auth';
import { getWebsiteType } from './utils';
// import { CREATE_ERROR } from './graphql_queries/errors';
import history from './history';
import { WEBSITE_TYPES } from './constants/enums';
import { DEFAULT_LOCALE } from './constants/locales';

const API_ENDPOINT = process.env.REACT_APP_API_URL;
const GRAPHQL_URI = `${API_ENDPOINT}graphql`;
const CLIENT_VERSION = process.env.REACT_APP_VERSION;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const WEBSITE_TYPE = getWebsiteType();
// eslint-disable-next-line no-console
console.log(`~~~Propida booting up~~~ API Endpoint: ${API_ENDPOINT}, Client Version: ${CLIENT_VERSION}, Website Type: ${WEBSITE_TYPE}, Sentry DSN: ${SENTRY_DSN}`);

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

moment.locale(DEFAULT_LOCALE);

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://api-staging.propida.se', 'https://api.propida.se'],
    // Session Replay
    replaysSessionSampleRate: 0, // We use Hotjar for session replay currently so we're only interested in sentry capturing errors
    replaysOnErrorSampleRate: 1.0,
  });
}

const request = async (operation) => {
  const tokens = getTokens();
  const headers = {
    'x-client-version': CLIENT_VERSION,
  };
  if (tokens && tokens.accessToken) {
    headers['x-access-token'] = tokens.accessToken;
    headers['x-refresh-token'] = tokens.refreshToken;
  }
  // If the user has a saved locale, send it to the server
  const savedLocale = localStorage.getItem('locale');
  if (savedLocale) {
    headers['accept-language'] = savedLocale;
  }
  operation.setContext({
    headers,
  });
};

const GlobalLoadingIndicator = () => {
  const status = useApolloNetworkStatus();
  // console.log(status)
  // if (status.numPendingQueries > 0) {
  //   return <p>Pending queries: ${status.numPendingQueries}</p>;
  // }

  // TODO: Not sure this is such a good idea. The error message modal will always be displayed on mutation errors.
  // It's fine if the error is not handled in the component, but if it is, the error will be shown twice
  if (status.mutationError) {
    return <ErrorMessage graphqlError={status.mutationError} modal />;
  }
  // To handle queryErrors globally, we need to remove the custom error messages in all of the components
  // if (status.queryError) {
  //   return <ErrorMessage graphqlError={status.queryError} modal />;
  // }
  return null;
};

const requestLink = new ApolloLink((operation, forward) => new Observable((observer) => {
  let handle;
  Promise.resolve(operation)
    .then((oper) => request(oper))
    .then(() => {
      handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    })
    .catch(observer.error.bind(observer));

  return () => {
    if (handle) handle.unsubscribe();
  };
}));

// eslint-disable-next-line arrow-body-style
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const { response: { headers } } = context;

    if (headers) {
      const mustRefresh = headers.get('x-must-refresh');

      // There can be a problem some time with the client sending a request with an old version of the client.
      // So don't trust the server 100%, instead check the client version and compare to the desired version
      if (mustRefresh && mustRefresh !== CLIENT_VERSION) {
        const forceParam = `force-refreshed=${mustRefresh}`;
        if (window.location.search.indexOf(forceParam) === -1) {
          window.location.search += `&${forceParam}`;
        }
      }
    }
    return response;
  });
});

// We have to disable normalization for the WorkCategory since when there are multiple sub orders with the same categories, but different extra data, they would just get duplicated in the cache.
// Disabling this means that these categories are embedded with their parent object instead (sub order).
// References: https://stackoverflow.com/questions/66266056/how-to-configure-apollo-cache-to-uniquely-identify-a-child-elements-based-on-the
// https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
const apolloCacheSettings = {
  typePolicies: {
    WorkCategory: {
      keyFields: false,
    },
    ClientContactUser: {
      keyFields: false,
    },
    AgreementCompanyRelation: {
      keyFields: false,
    },
    ContractorAgreementRelation: {
      keyFields: ['agreementId', 'companyId'],
    },
    Query: {
      fields: {
        agreementsByCompany: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
};

const defaultApolloOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const loggedOut = () => {
  if (WEBSITE_TYPE === WEBSITE_TYPES.STANDARD) {
    history.push('/login?logout=true');
  } else {
    // For tenants, we just show the error message received from the server. No redirect
  }
};

const client = new ApolloClient({
  cache: new InMemoryCache(apolloCacheSettings),
  defaultOptions: defaultApolloOptions,
  link: link.concat(ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        // User access token has expired
        if (graphQLErrors && graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
          // We assume we have both tokens needed to run the async request
          const tokens = getTokens();
          if (tokens && tokens.accessToken && tokens.refreshToken) {
            // Let's refresh token through async request
            return new Observable((observer) => {
              const postData = {
                refreshToken: tokens.refreshToken,
                grantType: 'refresh_token',
              };
              fetch(`${API_ENDPOINT}oauth2/token`, {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: {
                  'Content-Type': 'application/json',
                  'x-client-version': CLIENT_VERSION,
                },
              }).then((response) => {
                if (response.status === 200) {
                  response.json().then((data) => {
                    if (data?.login && data.login.accessToken && data.login.refreshToken) {
                      saveTokens(data.login);

                      const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                      };

                      // Retry last failed request
                      forward(operation).subscribe(subscriber);
                    }
                  });
                } else if (response.status === 401) {
                  loggedOut();
                  // An error can be thrown instead.
                  // One idea would be to throw a specific error, and if that error is seen in the component (generically), show a login box on the same page
                  // observer.error(new Error('must login!!'));
                }
              });
            });
          }
          loggedOut();
        } else {
          graphQLErrors.forEach(({ message, locations, path }) => {
            const errorMessage = `[GraphQL error]: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`;
            // eslint-disable-next-line no-console
            console.log(errorMessage);
            // TODO: No need to log to server since we catch this already on the server. Is it always like this?
            // console.log(`[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}, Operation: ${operation}`);
            // client.mutate({ mutation: CREATE_ERROR,
            //   variables: {
            //     errorMessage,
            //     userInfo: 'temp', // userInfo && JSON.stringify(userInfo),
            //   },
            // });
          });
          // response.errors = undefined;
        }
      }
      if (networkError) {
        // eslint-disable-next-line no-console
        console.log('[GraphQL network error]: ', networkError, 'Operation: ', operation);
      }
    }),
    afterwareLink,
    requestLink,
    new HttpLink({
      uri: GRAPHQL_URI,
      // fetch: customFetch,
      // credentials: 'include'
    }),
  ])),
});

// optional cofiguration
const options = {
  position: 'top center',
  timeout: 5000,
  offset: '60px',
  // you can also just use 'scale'
  transition: 'fade',
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <AlertProvider template={AlertTemplate} {...options}>
      <App><GlobalLoadingIndicator /></App>
    </AlertProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
