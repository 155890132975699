import { useContext, React } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import { UserContext } from '../contexts/UserContext';
import { getUser } from '../../utils/auth';
import { addScopeToSentry } from '../../utils/logging';

function ErrorBoundaryWithUserContext({ fallback, children }) {
  const { user } = useContext(UserContext);
  const userInfo = getUser();

  const userInfoDetails = userInfo || user;

  const beforeCapture = (scope) => {
    addScopeToSentry(scope, userInfoDetails);
  };

  return (
    <Sentry.ErrorBoundary
      fallback={fallback}
      beforeCapture={beforeCapture}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

ErrorBoundaryWithUserContext.propTypes = {
  fallback: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryWithUserContext;
