import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { UserContext } from './UserContext';
import { GET_SERVICE_CATEGORIES_FOR_PROPCOMP } from '../../graphql_queries/service_categories';
import { GET_SERVICE_CATEGORIES_FOR_CONTRACTOR } from '../../graphql_queries/service_categories.contractor';
import { GET_SERVICE_CATEGORIES_FOR_TENANT } from '../../graphql_queries/service_categories.tenant';

const GET_QUERY = {
  propcomp: GET_SERVICE_CATEGORIES_FOR_PROPCOMP,
  contractor: GET_SERVICE_CATEGORIES_FOR_CONTRACTOR,
  tenant: GET_SERVICE_CATEGORIES_FOR_TENANT,
};

const DATA_PROPERTY = {
  propcomp: 'serviceCategories',
  contractor: 'contractorServiceCategories',
  tenant: 'tenantServiceCategories',
};

function ConditionalProviderWithHook({ userType, children }) {
  const { loading, error, data } = useQuery(GET_QUERY[userType]);

  const value = useMemo(
    () => ({
      loading,
      error,
      data: data ? data[DATA_PROPERTY[userType]] : null,
    }),
    [loading, error, data],
  );

  return <ServiceCategoriesContext.Provider value={value}>{children}</ServiceCategoriesContext.Provider>;
}

const ServiceCategoriesContext = React.createContext([{}, () => {}]);
function ServiceCategoriesProvider({ children }) {
  const { user } = useContext(UserContext);

  if (!user || user.userType === 'extco') {
    return children;
  }

  return <ConditionalProviderWithHook userType={user.userType}>{children}</ConditionalProviderWithHook>;
}

ConditionalProviderWithHook.propTypes = {
  children: PropTypes.node.isRequired,
  userType: PropTypes.string.isRequired,
};

ServiceCategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ServiceCategoriesContext;

export { ServiceCategoriesContext, ServiceCategoriesProvider };
