import React, { useEffect, useState, Suspense } from 'react';
import { Router } from 'react-router-dom';
import ScrollRestoration from 'react-scroll-restoration';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import history from './history';
import { ability } from './config/ability';
import { WEBSITE_TYPES } from './constants/enums';
import { getWebsiteType } from './utils';

import { UserProvider } from './components/contexts/UserContext';
import { WorkCategoriesProvider } from './components/contexts/WorkCategoriesContext';
import { ReleaseNoteProvider } from './components/contexts/ReleaseNoteContext';
import { AbilityContext } from './components/contexts/AbilityContext';
import useGoogleAnalytics from './hooks/ga';
import { ServiceCategoriesProvider } from './components/contexts/ServiceCategoriesContext';
import { InternationalizationProvider } from './components/contexts/InternationalizationContext';

import './assets/styles/App.css';
import './assets/styles/Antd.css';

import './assets/styles/Recurrence.css';
import './components/orders/Orders.css';
import './components/companies/Companies.css';
import './components/companies/CompanyList.css';
import './components/companies/CompanyShow.css';
import Loader from './components/common/Loader';
import ErrorBoundaryWithUserContext from './components/common/ErrorBoundaryWithContext';
import { getUser } from './utils/auth';
import ErrorFallback from './components/common/ErrorFallback';
import { addScopeToSentry } from './utils/logging';
import { UserSettingsContext } from './components/contexts/UserSettingsContext';

const TenantRoutes = React.lazy(() => import('./routes/TenantRoutes'));
const StandardRoutes = React.lazy(() => import('./routes/StandardRoutes'));

const Routes = ({ children, loading, websiteType }) => {
  const gaId = process.env.REACT_APP_GA_ID;
  if (gaId) {
    useGoogleAnalytics(gaId);
  }

  return (
    <Suspense fallback={<Loader style={{ marginTop: '45vh' }} />}>
      {websiteType === WEBSITE_TYPES.TENANT
        ? <TenantRoutes loading={loading}>{children}</TenantRoutes>
        : <StandardRoutes loading={loading}>{children}</StandardRoutes>}
    </Suspense>
  );
};

Routes.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  websiteType: PropTypes.string.isRequired,
};

const App = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const websiteType = getWebsiteType();
  const [uncaughtError, setUncaughtError] = useState(null);
  const shouldIndex = process.env.REACT_APP_INDEXING === 'true';

  // This is used so that the no permission div is not displayed during loading, where we don't know if the user is authed yet
  async function onLoad() {
    setLoading(false);
  }

  const callSentry = (error) => {
    const userInfo = getUser();

    Sentry.withScope((scope) => {
      addScopeToSentry(scope, userInfo);
      Sentry.captureException(error);
    });
  };

  useEffect(() => {
    onLoad();

    // Global error handler for uncaught exceptions
    window.onerror = (message, source, lineno, colno, error) => {
      console.log('Uncaught error', { message, source, lineno, colno, error });
      callSentry(error);
      setUncaughtError(error);
    };

    // Global error handler for unhandled promise rejections
    window.onunhandledrejection = (event) => {
      console.log('Unhandled rejection', { event });
      callSentry(event.reason);
      setUncaughtError(event.reason);
    };

    // Cleanup global event handlers
    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  return (
    <div>
      <InternationalizationProvider>
        <Helmet>
          {process.env.REACT_APP_HOTJAR_ID && (
            <script>
              {`
                const hotjarId = ${process.env.REACT_APP_HOTJAR_ID};
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:hotjarId,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
            </script>
          )}
          {/* {gaId && <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />}
          {gaId && (
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', "${gaId}");
              `}
            </script>
          )} */}
          {!shouldIndex && <meta name="robots" content="noindex, nofollow" />}
        </Helmet>
        <UserProvider>
          <UserSettingsContext>
            <ErrorBoundaryWithUserContext fallback={<ErrorFallback />}>
              <WorkCategoriesProvider>
                <ServiceCategoriesProvider>
                  <ReleaseNoteProvider>
                    <AbilityContext.Provider value={ability}>
                      <Router history={history}>
                        <div>
                          <ScrollRestoration />
                          <Routes loading={loading} websiteType={websiteType}>
                            {children}
                            {uncaughtError && <ErrorFallback modal onModalClose={() => setUncaughtError(null)} />}
                          </Routes>
                        </div>
                      </Router>
                    </AbilityContext.Provider>
                  </ReleaseNoteProvider>
                </ServiceCategoriesProvider>
              </WorkCategoriesProvider>
            </ErrorBoundaryWithUserContext>
          </UserSettingsContext>
        </UserProvider>
      </InternationalizationProvider>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
