import * as jwtDecode from 'jwt-decode';

import { updateRules } from '../config/ability';

const TOKEN_KEY = process.env.REACT_APP_TOKEN_NAME;

export function saveTokens(tokens) {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));

  const decoded = jwtDecode(tokens.accessToken);
  updateRules(decoded.user.userType, decoded.user.userRole); // This might execute from somewhere else too. Shouldn't matter much though
}

export function getTokens() {
  return JSON.parse(localStorage.getItem(TOKEN_KEY));
}

export function getTokensString() {
  return localStorage.getItem(TOKEN_KEY);
}

export function getUserFromToken(tokens) {
  if (tokens) {
    const decoded = jwtDecode(tokens.accessToken);
    return decoded?.user;
  }
  return null;
}

export function getUser() {
  const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
  if (tokens) {
    const decoded = jwtDecode(tokens.accessToken);
    return decoded?.user;
  }
  return null;
}

export function deleteTokens() {
  localStorage.removeItem(TOKEN_KEY);
}

export function getStuff() {
  const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
  if (tokens) return getUserFromToken(tokens);
  return null;
}
