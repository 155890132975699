import { gql } from '@apollo/client';

export const GET_WORK_CATEGORIES_FOR_CONTRACTOR = gql`
query {
  contractorWorkCategories {
    id
    name
    text
    icon
    image
    parentId
    sortId
    level
    colour
    subComponent
    serviceCategoryId
  }
}
`;
